// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --background-color: #ffffff;
    --text-color: #000000;
  }
  
  .dark {
    --background-color: #1a1a1a;
    --text-color: #ffffff;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
  }`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,qBAAqB;EACvB;;EAEA;IACE,2BAA2B;IAC3B,qBAAqB;EACvB;;EAEA;IACE,yCAAyC;IACzC,wBAAwB;IACxB,uDAAuD;EACzD","sourcesContent":[":root {\n    --background-color: #ffffff;\n    --text-color: #000000;\n  }\n  \n  .dark {\n    --background-color: #1a1a1a;\n    --text-color: #ffffff;\n  }\n  \n  body {\n    background-color: var(--background-color);\n    color: var(--text-color);\n    transition: background-color 0.3s ease, color 0.3s ease;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
